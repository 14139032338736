import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import { useRedirect, useStores } from '../../hooks';


const Device = ({ img, text, link }) => {
  const { redirect } = useRedirect();

  return (
    <figure onClick={() => redirect(link)}>
      <img src={img} alt="device icon"/>
      <figcaption>
        <FormattedMessage id={text} />
      </figcaption>
    </figure>
  )
}

const Realisations = () => {
  const { clientStore } = useStores(['clientStore']);

  return useObserver(() => {
    const { clients } = clientStore;
    return (
      <main>
        <div className="subtitle">
          <h2>
            <FormattedMessage id="realisations" />
          </h2>
        </div>

        <div className="grid">
          {
            clients.map(client => (
              <Device img={client.img} text={client.name} link={`/projects/${client.link}`} key={client.img} />
            ))
          }
        </div>
      </main> 
    )
  })
}


export default Realisations;
