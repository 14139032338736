import { createIntl } from 'react-intl';
import { toast } from 'react-toastify';
import locales from "../assets/locales/locales.json";

export const translate = (string, values) => {
  const locale = localStorage.getItem('_site_lang') || 'en';

  const keys = Object.keys(locales);
  const objvalues = Object.values(locales);

  const messages = keys.reduce((obj, key, index) => {
    obj[key] = objvalues[index][locale.toLowerCase()];
    return obj;
  }, {});

  const intl = createIntl({ locale, messages });
  const result = intl.formatMessage({ id: string}, values);
  return result;
};

export class Messages {

  static error = (message, values, closetime = 2998) => {
    toast.error(translate(message, values), { position: toast.POSITION.TOP_CENTER, autoClose: closetime });
  };

  static success = (message, values, closetime = 2000) => {
    toast.success(translate(message, values), { position: toast.POSITION.TOP_CENTER, autoClose: closetime });
  };

  static short = (message, values) => {
    toast.success(translate(message, values), { position: toast.POSITION.TOP_CENTER, autoClose: 1000 });
  };

  static handleErrors = (errors) => {
    let html = `
    <div class="errorTitle">${translate('resolve_the_following_errors')}</div>
    <ul>`;

    errors.forEach((error) => {
      html += `<li>${error.error}</li>`;
    });

    html += '</ul>';

    toast.success({ html, classes: 'error' });
  };
}

