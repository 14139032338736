import React, { useEffect, useState } from 'react';
import { useObserver } from "mobx-react-lite"
import { FormattedMessage } from 'react-intl';

import { Label, Input, Textarea } from '../elements';
import { translate } from '../I18n';
import { useStores } from '../../hooks';
import { controlOnErrors } from '../../utils';

import styles from '../../assets/styles/contact.module.scss'
import mailImg from '../../assets/images/mail_sent.svg';

const Contact = ({ hideForm = false }) => {
  const [showMailSent, setShowMailSent] = useState(false);
  const { mailStore } = useStores(['mailStore']);


  useEffect(() => {
    setShowMailSent(false);
  }, [])

  const submit = async () => {
    const { mail } = mailStore;
    await controlOnErrors(mail);
    await mailStore.sendMail();
    setShowMailSent(true);
  };

  return useObserver(() => {
    const { mail } = mailStore;

    return (
      <main>
        {
          showMailSent && (
            <div className={styles.mailSent}>
              <div className="subtitle">
                <h2>your message has been successfully sent</h2>
              </div> 

              <div className={styles.imgContainer}>
                <img src={mailImg} alt="mail_sent" />
              </div>  
            </div>
            
          )
        }

        {
          !showMailSent && (
            <>
              <div className="subtitle">
                <h2>Contact</h2>
              </div>
              <p>
                <FormattedMessage id="contact_info" />
              </p>

              <div className={styles.formContainer}>
                {
                  !hideForm && (
                    <form>
                      <div>
                        <Label htmlFor="your_name"/>
                        <Input context={mail} name="name" placeholder={translate('enter_your_name')} />
                      </div>

                      <div>
                        <Label htmlFor="your_email"/>
                        <Input context={mail} name="email" placeholder={translate('enter_your_email')} />
                      </div>

                      <div>
                        <Label htmlFor="your_message"/>
                        <Textarea context={mail} name="message" placeholder={translate('enter_your_message')} />
                      </div>

                      <div className="btn" onClick={submit}>
                        <FormattedMessage id="send_your_message" />
                      </div>
                    </form>
                  )
                }

              </div>
            </>
          )
        }
      </main>
    )
  })
}

export default Contact;
