import React from 'react';
import { useObserver } from "mobx-react-lite"
import { FormattedMessage } from 'react-intl';

import {useStores, useRedirect} from '../../hooks';
import styles from '../../assets/styles/dev.module.scss';

const Developers = () => {
  const { redirect } = useRedirect();
  const { devStore } = useStores(['devStore']);

  const redirectTo = tutorial => {
    redirect(`/tutorials/${tutorial.id}`)
  };

  return useObserver(() => {
    const { tutorials } = devStore;

    return (
      <main>
        <h2>
          <FormattedMessage id="developers" />
        </h2>

        <p>
          <FormattedMessage id="developers_info" />
        </p>

        <h2 style={{marginTop: '50px'}}>Tutorials</h2>

        <div className="placeholder">
          The tutorials are coming soon.
        </div>

        {/* <section className={styles.tutorialGrid}> */}
        {/*   { */}
        {/*     tutorials.map(tutorial => { */}
        {/*       return ( */}
        {/*         <div className={styles.tutorial} key={tutorial.id} onClick={() => redirectTo(tutorial)}> */}
        {/*           <div className={styles.imgContainer}> */}
        {/*             <img src={tutorial.image} alt={tutorial.title} /> */}
        {/*           </div> */}

        {/*           <figcaption> */}
        {/*             <h3 className={styles.title}> */}
        {/*               {tutorial.title} */}
        {/*             </h3> */}

        {/*             <p> */}
        {/*               {tutorial.description} */}
        {/*             </p> */}

                    
        {/*           </figcaption> */}

        {/*           <span className={styles.readMore} onClick={() => redirectTo(tutorial)}> */}
        {/*             <FormattedMessage id="read_more" /> */}
        {/*           </span> */}
        {/*         </div> */}
        {/*       ) */}
        {/*     }) */}
        {/*   } */}
        {/* </section> */}
        
      </main>
    )
  })
}

export default Developers;

