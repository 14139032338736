import React from 'react';
import { FormattedMessage } from 'react-intl';

const Label = ({ htmlFor, required = false, ...rest }) => (
  <label htmlFor={htmlFor} {...rest}>
    <FormattedMessage id={htmlFor}/>
    {
      required && (
        <span className="required">*</span>
      )
    }
  </label>
);

export default Label;
