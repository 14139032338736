import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useObserver } from 'mobx-react-lite';
import styles from '../../assets/styles/business.module.scss';
import iotImg from '../../assets/images/iot.svg';

const Business = () => {
  return useObserver(() => {
    return (
      <main className={styles.container}>
        <section>
          <h2>
            <FormattedMessage id="whats_iot_and_why_would_you_want_it" />
          </h2>

          <div className={styles.iotInfo}>
            <p>
              <FormattedMessage id="whats_iot_info" />
            </p>

            <img src={iotImg} alt="iot img" />

          </div>


        </section>

        <section>
          <h2>
            <FormattedMessage id="benefits_from_iot" />
          </h2>

          <div className={styles.benefits}>
            <div>
              <h3>
                <i className="fas fa-microchip" />
                <FormattedMessage id="software_on_the_device" />
              </h3>

              <p>
                <FormattedMessage id="software_on_the_device_info" />
              </p>
            </div>

            <div>
              <h3>
                <i className="fas fa-cloud" />
                <FormattedMessage id="no_cloud_required" />
              </h3>

              <p>
                <FormattedMessage id="no_cloud_required_info" />
              </p>
            </div>

            <div>
              <h3>
                <i className="fas fa-user-lock" />
                <FormattedMessage id="high_privacy" />
              </h3>

              <p>
                <FormattedMessage id="high_privacy_info" />
              </p>
            </div>

            <div>
              <h3>
                <i className="fas fa-user-friends" />
                <FormattedMessage id="blueapp" />
              </h3>

              <p>
                <FormattedMessage id="blueapp_info" />
              </p>
            </div>
          </div>

        </section>
      </main>
    )
  })
};

export default Business;
