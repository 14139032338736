import React from 'react';
import {useObserver} from "mobx-react-lite"

import styles from '../../assets/styles/dev.module.scss';
import { CopyBlock, obsidian } from "react-code-blocks";

const CodeBlock = ({ language = 'javascript', code }) => {
  return useObserver(() => {
    return (
      <div className={styles.codeExample}>
        <CopyBlock 
          text={code}
          language={language}
          theme={obsidian}
        />
      </div>
    )
  })
};

export default CodeBlock;

