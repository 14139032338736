
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const Birthwatch = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'Birth Watch',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="birthwatch_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage 
            id="possibilities_info_birthwatch" 
          />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_birthwatch" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:info@hafaro.nl">info@hafaro.nl</a>
          </div>

          <div>
            <i className="fas fa-phone" />
            <a href="tel:+31634761309">+31-(0)634 761 309</a>
          </div>

          <div>
            <i className="fas fa-globe" />
            <a href="https://www.birth-watch.com">www.birth-watch.com</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            <img src={require("./images/IMG_1567.jpg" ).default} alt="" />
          </div>

          <div>
            <img src={require("./images/IMG_1572.jpg" ).default} alt="" />
          </div>

          <div>
            <img src={require("./images/ble_mesh_gw.png" ).default} alt="" />
          </div>

          <div>
            <img src={require("./images/a1501a_16ca45b33b034ca4aefd4f7719cae87c_mv2.png" ).default} alt="" />
          </div>

          <div>
            <img src={require("./images/a1501a_bd5c35330bfb473784614fd82073e25d_mv2.png" ).default} alt="" />
          </div>
        </div>
      </section>
    </main>
  )
};


export default Birthwatch;
