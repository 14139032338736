import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../assets/styles/dev.module.scss';

import { CodeBlock, OptionsBlock } from '../elements';

const ReactTutorial = () => {
  return (
    <>
      <h2 id="getting-started">
        <FormattedMessage id="get_started" />
      </h2>

      <p>
        <FormattedMessage id="c_react_get_started" />
      </p>

      <div className={styles.requirements}>
        <p>
          Requirements:
        </p>

        <ul>
          <li>- NodeJS installed</li>
          <li>- An active BlueCherry device subscription (this can be bought via the webshop)</li>
          <li>- Access to your provisioned device</li>
          <li>- Active BC Client</li>
        </ul>
      </div>

      <div>
        <div className={styles.gettingStartedContainer} id="create-react-project">
          <p className={styles.title}>
            <FormattedMessage id="t_create_react_project" />
          </p>

          <CodeBlock code="npx create-react-app bluecherry-example" language="bash" />

          <p>
            <FormattedMessage id="c_create_react_project" />
          </p>

          <CodeBlock code="cd bluecherry-example" language="bash" />

        </div>

        <div className={styles.gettingStartedContainer} id="install-bc-sdk">
          <p className={styles.title}>
            <FormattedMessage id="t_install_the_bluecherry_sdk" />
          </p>

          <p>
            <FormattedMessage id="c_install_the_bluecherry_sdk" />
          </p>


          <CodeBlock code="yarn add bluecherry-communication" language="bash" />
        </div>

        <div className={styles.gettingStartedContainer} id="nginx-setup">
          <p className={styles.title}>
            <FormattedMessage id="t_setup_nginx_for_your_app" />
          </p>

          <p>
            <FormattedMessage id="c_setup_nginx_for_your_app" />
          </p>

          <OptionsBlock>
            <div data-id="OSX">
              <p>
                <FormattedMessage id="c_open_our_nginx_config" />
              </p>
              <CodeBlock code="sudo vim /usr/local/etc/nginx/nginx.conf" language="bash" />

              <p>
                <FormattedMessage id="c_create_a_new_server_block" />
              </p>
              <CodeBlock code={`
server {
	listen 80;
	server_name bluecherry.example;
	

        location / {
            proxy_pass http://127.0.0.1:3000/;
            proxy_set_header Host $host;
        }

        // this acts as proxy -> all api calls starting with /api/internal/messages will be forwarded to our localhost with port 43770 (the default port for our BlueCherry-client)

        location /api/internal/messages {
            proxy_pass http://127.0.0.1:43770;
            proxy_set_header Host $host;
        }
}
              `} language="bash" />


              <p>
                <FormattedMessage id="c_adjust_host_files" />
              </p>
              <CodeBlock code="sudo vim /etc/hosts" language="bash" />

              <p>
                <FormattedMessage id="c_add_2_lines_to_your_host_file" />
              </p>
              <CodeBlock code={`
127.0.0.1 bluecherry.example
::1 	  bluecherry.example
              `} language="bash" />

              <p>
                <FormattedMessage id="c_your_configuration_is_now_done" />
              </p>

            </div>

          </OptionsBlock>


        </div>

        <div  className={styles.gettingStartedContainer} id="example-react">
          <p className={styles.title}>
            <FormattedMessage id="t_example_react_app" />
          </p>

          <CodeBlock 
            code={`
import { useState } from "react";
import { sendMail, setUrlPrefix } from 'bluecherry-communication';

// set url prefix to empty is required to use our nginx proxy. By default messages would be prefixed with 'http://127.0.0.1:47330'
setUrlPrefix('');

class Form {
  emailAddress: string;
  message: string;
  subject: string;

  constructor() {
    this.emailAddress = '';
    this.message = '';
    this.subject = '';
  }
}

function App() {
  const [form, updateForm] = useState(new Form());

  const send = (ev: any) => {
    ev.preventDefault();
    const { emailAddress, message, subject } = form;

    sendMail({
      destination: emailAddress,
      subject,
      plain: message,
      html: message
    });
  };

  const onUpdate = (ev: any) => {
    const { name, value } = ev.target;

    updateForm({
      ...form,
      [name]: value
    });
  };

  return (
    <>
      <form id="emailForm" onSubmit={send}>
        <input 
          type="email" 
          value={form['emailAddress']} 
          name="emailAddress" 
          onChange={onUpdate} 
          placeholder="email"
        />

        <input 
          type="text" 
          value={form['subject']} 
          name="subject" 
          onChange={onUpdate} 
          placeholder="subject"
        />

        <textarea 
          name="message" 
          cols={30} 
          rows={10} 
          value={form['message']} 
          onChange={onUpdate} 
          placeholder="your message"
        />

        <button type="submit" form="emailForm">
          Submit
        </button>
      </form>
    </>
  );
}

export default App;
             `}

            language="javascript"
          />

        </div>
      </div>
    </>
  )
};

export default ReactTutorial;

