import React from 'react';
import { useObserver } from "mobx-react-lite"
import { useRedirect, useStores } from "../../hooks";

import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";

const Carousel = () => {
  const { clientStore } = useStores(['clientStore']);
  const { redirect } = useRedirect();

  const redirectToClient = client => {
    redirect(`/projects/${client.link}`);
  };

  return useObserver(() => {
    const { clients } = clientStore;

    return (
      <AliceCarousel autoPlay autoPlayInterval="1500" autoWidth infinite autoPlayStrategy="none">
        {
          clients.map(client => (
            <img key={client.img} src={client.img} className="sliderimg" alt="" onClick={() => redirectToClient(client)}/>
          ))
        }
      </AliceCarousel>
    )
  });
};

export default Carousel;

