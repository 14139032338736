
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const Birthwatch = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'Rovin',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="rovin_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage 
            id="possibilities_info_rovin" 
          />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_rovin" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:info@rovin.eu">info@rovin.eu</a>
          </div>

          <div>
            <i className="fas fa-globe" />
            <a href="https://www.rovin.eu">www.rovin.eu</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          

          <div>
            <img src={require('./images/ROV2.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/ROV3.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/ROV4.jpg').default} alt="" />
          </div>
        </div>
      </section>
    </main>
  )
};


export default Birthwatch;
