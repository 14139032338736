import { decorate, observable } from "mobx";
import { doFetch } from '../utils';
import { Mail } from "../components/models";

const URLS = {
  SEND_MAIL: '/api/mail/send'
}

class MailStore {
  constructor() {
    this.mail = new Mail();
  }

  sendMail = () => {
    return doFetch(URLS.SEND_MAIL, 'POST', this.mail.toJS());
  };
}

decorate(MailStore, {
  mail: observable
});

export default new MailStore();

