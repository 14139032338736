import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from 'react-loader-spinner'
import { useObserver } from 'mobx-react-lite';
import {useStores} from '../../hooks';

const DownloadButton = ({ text, file, filename }) => {
  const [executing, setExecuting] = useState(false);
  const { fileStore } = useStores(['fileStore']);

  const execute = async () => {
    try {
      if (!executing) {
        setExecuting(true);
        await fileStore.downloadFile(file, filename);
        setExecuting(false);

      }
    } catch  {
      setExecuting(false);
    }
  };

  return useObserver(() => {
    return (
      <div className="btn" onClick={execute} style={{marginBottom: '10px'}}>
        <FormattedMessage id={text}/>

        {
          executing && (
            <div style={{marginLeft: '10px'}}>
              <Loader
                type="Puff"
                color="#fff"
                height={20}
                width={20}
              />
            </div>
          )
        }
        {
          !executing && (
            <i className="fas fa-download" aria-hidden="true" style={{marginLeft: '10px'}}/>
          )
        }
      </div>
    )
  });
};


export default DownloadButton;
