import React from 'react';
import globalStore from './stores/global';

export const URL = '';

export const richTextConfig = {
  ul: chunks => <ul>{chunks}</ul>,
  li: chunks => <li>{chunks}</li>,
  br: () => <br/>,
  p: chunks => <p>{chunks}</p>,
  div: chunks => <div>{chunks}</div>,
  section: chunks => <section>{chunks}</section>,
  a: chunks => <a>{chunks}</a>,
  figure: chunks => <figure>{chunks}</figure>,
  figcaption: chunks => <figcaption>{chunks}</figcaption>,
  header: chunks => <header>{chunks}</header>,
  footer: chunks => <footer>{chunks}</footer>,
  strong: chunks => <strong>{chunks}</strong>,
  span: chunks => <span>{chunks}</span>
}

export const supressErrorNames = () => {
  if (process.env.NODE_ENV !== 'production') {
    const originalConsoleError = console.error;
    if (console.error === originalConsoleError) {
      console.error = (...args) => {

        if (args[0].toString().includes('[React Intl]')) {
          return;
        }

        if (args[0].toString().indexOf('[React Intl] Cannot format message:') === 0 || args[0].toString().indexOf('[React Intl] Missing message:') === 0) {
          return;
        }
        originalConsoleError.call(console, ...args);
      };
    }
  }
};

export const doFetch = async (url, method, body) => {
  const options = {
    method,
    credentials: "same-origin",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };

  let statusCode;
  return fetch(url, options)
    .then(res => {
      statusCode = res.status;
      return res.json()
    })
    .then(res => {
      if (statusCode !== 200) throw res.message;
      else return res;
    })
};

export const doFetchBlob = async (url, method, body) => {
  const options = {
    method,
    credentials: "same-origin",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  };

  let statusCode;
  return fetch(url, options)
    .then(res => {
      statusCode = res.status;
      return res.blob()
    })
    .then(res => {
      if (statusCode !== 200) throw res.message;
      else return res;
    })
}


const asyncForEach = async function (array, callback) {
  for (let index = 0; index < array.length; index += 1) {
    await callback(array[index], index, array);
  }
};

export const controlOnErrors = async (entity, validation) => {
  const keys = Object.keys(validation !== undefined ? validation : entity.validation);
  const errors = [];
  let hasErrors = false;

  await asyncForEach(keys, async (key) => {
    const valueToCheck = key.includes('.')
      ? key.split('.').reduce((obj, child) => obj[child], entity)
      : entity[key]


    const result = (validation !== undefined)
      ? await validation[key](valueToCheck)
      : await entity.validation[key](valueToCheck);


    entity[`error${key.replace(/\./g, '')}`] = result;

    if (result.error) {
      errors.push({ key, error: result.message });
      hasErrors = true;
    }
  });

  return new Promise((resolve, reject) => {
    if (hasErrors) {
      reject(errors);
    } else {
      resolve(errors);
    }
  });
};

export const openModal = modalName => {
  globalStore.openModal(modalName);
};

export const closeModal = modalName => {
  globalStore.closeModal(modalName);
};
