import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const Veenhuizen = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'Veenhuizen',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="veenhuizen_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage id="possibilities_info_veenhuizen" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_veenhuizen" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <span>info@veenhuizen.io</span>
          </div>

          <div>
            <i className="fas fa-phone" />
            <span>Telno</span>
          </div>

          <div>
            <i className="fas fa-globe" />
            <span>veenhuizen.io</span>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            Img1
          </div>

          <div>
            Img2
          </div>

          <div>
            Img3
          </div>

          <div>
            Img1
          </div>

          <div>
            Img2
          </div>

          <div>
            Img3
          </div>
        </div>
      </section>
    </main>
  )
};


export default Veenhuizen;
