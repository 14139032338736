import React from 'react';
import { useObserver } from "mobx-react-lite"
import { FormattedMessage } from 'react-intl';
import styles from '../../assets/styles/pricing.module.scss';

import { useRedirect }  from '../../hooks';

const Pricing = () => {
  const { redirect } = useRedirect();

  const redirectToRegister = () => {
    window.location.href = 'https://sso.bluecherry.io/register';
  };

  const redirectToContact = () => {
    redirect('/contact');
  };

  return useObserver(() => {
    return (
      <main>
        <div className="subtitle">
          <h2>
            <FormattedMessage id="bluecherry_pricing" />
          </h2>
        </div>

        <p>
          <FormattedMessage id="bluecherry_pricing_info" />
        </p>

        <div className={styles.pricingContainers}>
          <div className={styles.container}>
            <div>
              <h3>
                <FormattedMessage id="customer" />
              </h3>  
              <h4>
                <strong>
                  €1
                </strong>
                 /<FormattedMessage id="month" /> 
              </h4>
            </div>

            <ul>
              <li>
                <i className="fas fa-check-circle" />
                <span>
                  <FormattedMessage id="up_to_50_devices" />
                </span>
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <span>
                  <FormattedMessage id="receive_notifications" />
                </span>
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <span>
                  <FormattedMessage id="up_to_4_co_users" />
                </span>
              </li>
            </ul>

            <div className="btn" onClick={redirectToRegister}>
              <FormattedMessage id="register" />
            </div>
          </div>

          <div className={styles.container}>
            <div>
              <h3>
                <FormattedMessage id="company" />
              </h3>  
              <h4>
                <strong>
                  <FormattedMessage id="on_request" />
                </strong>
              </h4>
            </div>

            <ul>
              <li>
                <i className="fas fa-check-circle" />
                <span>Access to the BlueApp</span>
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <span>
                  <FormattedMessage id="overview_of_your_clients" />
                </span>
              </li>
              <li>
                <i className="fas fa-check-circle" />
                <span>
                  <FormattedMessage id="easy_to_use" />
                </span>
              </li>
            </ul>

            <div className="btn" onClick={redirectToContact}>
              <FormattedMessage id="contact_us" />
            </div>
          </div>
        </div>
      </main>
    )
  })
};


export default Pricing;

