import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OptionsBlock, CodeBlock, DownloadButton } from '../elements';
import styles from '../../assets/styles/dev.module.scss';
import files from '../Files';

const GettingStarted = () => {
  return (
    <>
      <h2 id="getting-started">
        <FormattedMessage id="get_started" />
      </h2>

      <p>
        <FormattedMessage id="c_configure_get_started" />
      </p>

      <div className={styles.requirements}>
        <p>
          Requirements:
        </p>

        <ul>
          <li>- An active BlueCherry account</li>
          <li>- An active BlueCherry device subscription (this can be bought via the webshop)</li>
          <li>- Access to the device you want to link</li>
        </ul>
      </div>

      <div>
        <div className={styles.gettingStartedContainer} id="install-bc-client">
          <p className={styles.title}>
            <FormattedMessage id="t_install_the_bluecherry_client" />
          </p>

          <OptionsBlock>
            <div data-id="OSX">
              <DownloadButton 
                text="bluecherry-client.dmg" 
                file={files.OSX_CLIENT}
              />
            </div>

            <div data-id="LINUX">
              <DownloadButton 
                text="bluecherry-client.deb" 
                file={files.LINUX}
              />

              <DownloadButton 
                text="bluecherry-client-32.deb" 
                file={files.LINUX_32}
              />
            </div>

            <div data-id="WINDOWS">
              <DownloadButton 
                text="bluecherry-client.msi" 
                file={files.WINDOWS}
              />

              <DownloadButton 
                text="bluecherry-client-32.msi" 
                file={files.WINDOWS_32}
              />
            </div>
          </OptionsBlock>

        </div>

        <div className={styles.gettingStartedContainer} id="provision-device">
          <p className={styles.title}>
            <FormattedMessage id="t_provision_your_device" />
          </p>

          <p>
            <FormattedMessage id="c_provision_your_device" />
          </p>

          <CodeBlock language="bash" code="./bluecherry-client provision"/>

          <p>
            <FormattedMessage id="c_after_provision" />
          </p>
        </div>

        <div className={styles.gettingStartedContainer} id="start-client">
          <p className={styles.title}>
            <FormattedMessage id="t_start_your_client" />
          </p>

          <p>
            <FormattedMessage id="c_start_your_client" />
          </p>

          <CodeBlock language="bash" code="./bluecherry-client"/>
        </div>
      </div>
    </>
  )
};

export default GettingStarted;
