import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const Trusteat = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'Trusteat',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="trusteat_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage id="possibilities_info_trusteat" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_trusteat" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:contact@trust-eat.com">contact@trust-eat.com</a>
          </div>

          <div>
            <i className="fas fa-globe" />
            <a href="https://www.trusteat.com">www.trusteat.com</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            <img src={require('./images/TE1.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/TE2.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/TE4.png').default} alt="" />
          </div>

          <div>
            <img src={require('./images/TE3.jpeg').default} alt="" />
          </div>

        </div>
      </section>
    </main>
  )
};


export default Trusteat;
