import React, {useState, useEffect} from 'react';
import { useStores } from '../../hooks';
import {observe} from 'mobx';

import styles from '../../assets/styles/optionsBlock.module.scss';

const getChildren = children => {
  if (!Array.isArray(children)) {
    return [children]
  }

  return children;
};

const OptionsBlock = ({ children }) => {
  const { global } = useStores(['global']);
  const [activeChild, setActiveChild] = useState(undefined);

  const labels = getChildren(children).map(child => child.props['data-id']);

  if (children.length === 0) {
    console.error('OptionsBlockV2 has no children');
  }

  useEffect(() => {
    setActiveChild(getChildren(children)[0]);

    const disposer = observe(global, 'selectedOption', ({newValue}) => {
      const match = getChildren(children).find(child => child.props['data-id'] === newValue);

      if (match) {
        setActiveChild(match)
      }
    })

    return () => {
      disposer();
    }

  }, [])


  const updateActiveChild = label => {
    const activeChild = getChildren(children).find(child => child.props['data-id'] === label);

    if (activeChild) {
      setActiveChild(activeChild);
      global.selectedOption = label;
    }
  };

  const isActive = label => {
    if (activeChild && activeChild.props['data-id'] === label) {
      return styles.active;
    }
  };

  return (
    <div className={styles.optionsBlock}>
      <div className={styles.options}>
        {
          labels.map((label, index) => (
            <div className={isActive(label)} onClick={() => updateActiveChild(label)} key={index}>
              {label}
            </div>
          ))
        }
      </div>


      <div className={styles.activeChild}>
        {
          activeChild
        }
      </div>
    </div>
  )
};

export default OptionsBlock;
