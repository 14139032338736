import React from 'react';
import { useObserver } from "mobx-react-lite"
import { FormattedMessage } from 'react-intl';
import { Carousel } from '../partials';


import securityImg from '../../assets/images/security.svg';
import cloudlessImg from '../../assets/images/cloudless.svg';
import setupImg from '../../assets/images/setup.svg';
import styles from '../../assets/styles/home.module.scss';

const Home = () => {
  return useObserver(() => {
    return (
      <main className={styles.container} style={{paddingTop: 0}}>

        <section className={styles.carousel}>
          <div className={styles.header}>
            <h2>
              <FormattedMessage id="trusted_by_our_valued_customers" />
            </h2>
          </div>

          <Carousel />
        </section>

        <section className={styles.section}>
          <h2>
            <FormattedMessage id="easy_to_use" />
          </h2>

          <div>
            <img src={setupImg} alt="setup svg" className={styles.easyToUse} />

            <FormattedMessage id="setup_info" />
          </div>
        </section>

        <section className={styles.section}>
          <h2>
            <FormattedMessage id="cloudless" />
          </h2>


          <div className={styles.desktop}>
            <p>
              <FormattedMessage id="cloudless_info" />
            </p>

            <img src={cloudlessImg} alt="cloudless svg" className={styles.right}/>
          </div>

          <div className={styles.mobile}>
            <img src={cloudlessImg} alt="cloudless svg" className={styles.right}/>

            <FormattedMessage id="cloudless_info" />
          </div>

        </section>

        <section className={styles.section}>
          <h2>
            <FormattedMessage id="secure" />
          </h2>

          <div>
            <img src={securityImg} alt="security svg" className={styles.left}/>

            <FormattedMessage id="security_info" />
          </div>
        </section>
      </main>
    )
  })
};

export default Home;
