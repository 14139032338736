import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const Omnic = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'Omnic',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="omnic_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage id="possibilities_info_omnic" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_omnic" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:pedro.rappe@telenet.be">pedro.rappe@telenet.be</a>
          </div>

          <div>
            <i className="fas fa-phone" />
            <a href="tel:+32478260535">+32 (0)478 26 05 35</a>
          </div>

          <div>
            <i className="fas fa-globe" />
            <a href="https://www.omni-c.weebly.com">www.omni-c.weebly.com</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            <img src={require('./images/OC1.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/OC2.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/OC5.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/OC6.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/OC4_.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/OMC3.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/concentrator.png').default} alt="" />
          </div>
        </div>
      </section>
    </main>
  )
};


export default Omnic;
