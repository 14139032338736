import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const NormaCoolNord = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'NormaCoolNord',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="normacoolnord_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage id="possibilities_info_normacoolnord" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_normacoolnord" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:info@nomacool.fr">info@nomacool.fr</a>
          </div>

          <div>
            <i className="fas fa-phone" />
            <a href="tel:+33320976104">+33-(0)3 20 97 61 04</a>
          </div>

          <div>
            <i className="fas fa-globe" />
            <a href="https://www.nomacool.fr">www.nomacool.fr</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            <img src={require('./images/MNC1.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/NMC2.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/Noma Cool.JPG').default} alt="" />
          </div>
        </div>
      </section>
    </main>
  )
};


export default NormaCoolNord;
