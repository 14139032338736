import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { translate } from '../I18n';
import { setValue, getValue, getErrorMessage, validate } from './IO';

const Textarea = props => { 
  const {
    placeholder = '',
    type = 'text',
    className = '',
    disableAutoFill = false,
    maxInput,
    ...rest
  } = props;

  const onChange = ev => {
    if  ((maxInput !== undefined && maxInput > ev.target.value) || maxInput === undefined)  {
      setValue(props, ev.target.value);
    }
  };

  const onValidate = ev => {
    validate(props, ev.target.value);
  };

  const getStatus = () => {
    const { error, message } = getErrorMessage(props) || {};

    if (error !== undefined && error) {
      return {
        hasError: true,
        message,
        placeholder: translate(placeholder)
      }
    } else {
      return {
        hasError: error !== undefined && error,
        placeholder: translate(placeholder)
      }
    }
  };

  return useObserver(() => { 
    const { hasError, placeholder, message } = getStatus();

    return (
      <>
        <textarea 
          className={`${className} ${hasError && 'invalid'}`}
          type={type}
          onChange={onChange}
          value={getValue(props)}
          onBlur={onValidate}
          placeholder={placeholder}
          autoComplete={disableAutoFill && 'none'}
          {...rest}
        />

        {
          hasError && (
            <div className="inputError">
              <span className="errorMessage">
                { message }
              </span>
            </div>
          )
        } 
      </>
    )
  })
}

export default Textarea;
