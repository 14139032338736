import React, {useEffect, useState} from 'react';
import { useObserver } from 'mobx-react-lite';
import {useLocation, Link} from 'react-router-dom';


import { FormattedMessage } from 'react-intl';
import {useRedirect, useStores} from '../../hooks';

import styles from '../../assets/styles/header.module.scss';
import logo from '../../assets/images/logo_cherry_bluecherry.svg';
import headerImg from '../../assets/images/header.svg';
import asideLogo from '../../assets/images/bluecherry_white.svg';

const AsideLink = ({ link, text, icon, onClick, ...rest }) => {
  const { pathname } = useLocation();

  const isActive = () => {
    if (link === '/developers' && pathname.includes('tutorial') || link === '/realisations' && pathname.includes('projects')) {
      return styles.active;
    }

    return pathname === link ? styles.active : undefined
  };

  const closeSideNav = () => {
    window.scroll({
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });

    if (onClick) {
      onClick();
    }
  };

  return (
    <li className={isActive()} {...rest}>
      <Link to={link} onClick={closeSideNav}>
        <i className={icon} />
        <FormattedMessage id={text} />
      </Link>
    </li>
  )
}

const Languages = ({ languages, aside = false }) => {
  const { global } = useStores(['global']);
  const [isOpen, setIsOpen] = useState(false);

  const updateLang = lang => {
    setIsOpen(false);
    global.lang = lang.short
  };

  return useObserver(() => {
    return (
      <div className={styles.languageContainer}>
        <span className={styles.language} onClick={() => setIsOpen(!isOpen)}>
          {global.lang.toUpperCase()}
          <i className="fas fa-chevron-down" />
        </span>

        {
          aside && isOpen && (
            <div className={styles.languageOverlay} onClick={() => setIsOpen(false)}/>
          )
        }

        <ul className={`${styles.langOverlay} ${aside ? styles.mobileLang : ''}`} style={ aside ? {display: isOpen ? 'block' : 'none'} : {}}>
          {
            languages.map(lang => (
              <li 
                onClick={() => updateLang(lang)} 
                className={global.lang === lang.short ? styles.activeLang : undefined}
                key={lang.short}
              >
                {lang.long}
              </li>
            ))
          }
        </ul>  
      </div>
    )
  })
};

const Links = ({ onClick }) => {
  return (
    <ul>
      <AsideLink onClick={onClick} text="home" link="/" icon="fas fa-home"/>
      <AsideLink onClick={onClick} text="realisations" link="/realisations" icon="fas fa-tasks"/>
      <AsideLink onClick={onClick} text="pricing" link="/pricing" icon="fas fa-credit-card"/>
      <AsideLink onClick={onClick} text="B2B" link="/b2b" icon="fas fa-building"/>
      <AsideLink onClick={onClick} text="developers" link="/developers" icon="fas fa-code"/>
      <AsideLink onClick={onClick} text="contact" link="/contact" icon="fas fa-envelope"/>
    </ul>
  )
};

const Header = () => {
  const { redirect } = useRedirect();
  const location = useLocation();
  const { global } = useStores(['global']);
  const [sidenavActive, setSidenavActive] = useState(false);
  const [allowedToShowImage, setAllowedToShowImage] = useState(false);

  useEffect(() => {
    const onScroll = function() {
      if (this.scrollY > 80) {
        document.getElementsByTagName('nav')[0].classList.add('fixed');
        document.getElementById('mobileHeader').classList.add('fixed')
        setAllowedToShowImage(true);
      } else if (this.scrollY < 80) {
        document.getElementsByTagName('nav')[0].classList.remove('fixed');
        document.getElementById('mobileHeader').classList.remove('fixed')
        setAllowedToShowImage(false);
      }
    }

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, []);

  const register = () => {
    window.location.href = 'https://sso.bluecherry.io/register';
  };

  return useObserver(() => { 
    const { languages } = global;

    return (
      <>
        <div>
          <div className={styles.overlay} style={{display: sidenavActive ? 'inherit' : 'none'}} onClick={() => setSidenavActive(false)}/>

          <aside className={styles.aside} style={sidenavActive ? {} : {maxWidth: 0}}>
            <Languages languages={languages} aside/>

            <i className={`${styles.close} fas fa-times`} onClick={() => setSidenavActive(false)}/>

            <div className={styles.imgContainer}>
              <img src={asideLogo} alt="bluecherry" />
            </div>

            <Links onClick={() => setSidenavActive(false)}/>

            <a href="https://user.bluecherry.io" className={styles.login}>
              <FormattedMessage id="login" />
            </a>


            <span className={styles.copyright}>© 2021 Interkim N.V.</span>
          </aside>
        </div>

        <header className={`${styles.header} ${location.pathname !== '/' ? styles.detailHeader : undefined}`}>

          <div className={styles.desktopHeader}>
            <nav>
              <img src={logo} alt="BlueCherry" onClick={() => redirect('/')}/>

              <Links /> 

              <Languages languages={languages} />

              <a href="https://user.bluecherry.io" className={styles.login}>
                <FormattedMessage id="login" />
              </a>

            </nav>
          </div>

          <div id="mobileHeader" className={styles.mobileHeader}>
            <i className={`fa fa-bars ${styles.mobileTrigger}` } onClick={() => setSidenavActive(!sidenavActive)}/>
            {
              (location.pathname !== '/' || (location.pathname === '/' && allowedToShowImage)) && (
                <img src={logo} alt="BlueCherry" onClick={() => redirect('/')}/>
              )
            }
          </div>


          {
            location.pathname === '/' && (
              <>
                <img className={styles.headerImg} src={headerImg} alt="cpu" />
                <div className={styles.title}>
                  <h1>
                    BlueCherry
                  </h1>

                  <h2>
                    Connect. Anything. Anywhere
                  </h2>

                  <p>
                    <FormattedMessage id="header_cta_info" />
                  </p>

                  <div className={styles.ctas}>
                    <div className="btn" onClick={() => redirect('/contact')}>
                      <FormattedMessage id="contact_us" />
                    </div>

                    <div className="btn" onClick={register}>
                      <FormattedMessage id="register_now" />
                      <i className="fas fa-chevron-right" />
                    </div>
                  </div>
                </div>           
              </>
            )
          }
        </header>  
      </>
      
    )

  });
};

export default Header;
