import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const Came = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'Came ACS 01',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="came_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage id="possibilities_info_came" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_came" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-globe" />
            <a href="https://www.came.com">www.came.com</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            <img src={require('./images/img1.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/img2.png').default} alt="" />
          </div>

          <div>
            <img src={require('./images/img3.png').default} alt="" />
          </div>

          <div>
            <img src={require('./images/img4.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/img5.jpeg').default} alt="" />
          </div>
        </div>
      </section>
    </main>
  )
};


export default Came;
