import React, {useEffect, useState} from 'react';
import { useObserver } from 'mobx-react-lite';
import { Switch, Route, BrowserRouter, Redirect, useLocation } from 'react-router-dom';
import { Home, Contact, Pricing, Developers, Business, Tutorials, Realisations } from './pages';

import * as Projects from './Projects';

import {IntlProvider} from 'react-intl';
import { Header, Footer } from './global';
import { useStore, useStores } from '../hooks';
import Wrapper from './Wrapper';
import locales from "../assets/locales/locales.json";
import wave from '../assets/images/header_wave.svg';
import { richTextConfig } from '../utils';

const WaveContainer = () => {
  const { pathname } = useLocation();

  return pathname === '/' && (
    <div className="waveContainer">
      <img src={wave} alt="wave" />
    </div>
  )
}

const getDynamicComponent = ({ match }) => {
  const { clientStore } = useStores(['clientStore']);
  const [content, setContent] = useState(null);

  const { params } = match;
  const { project } = params;

  useEffect(() => {
    const client = clientStore.clients.find(client => {
      return client.link.toLowerCase() === project.toLowerCase()
    }); 

    setContent(Projects[client.link]);
  }, []);

  return content;
};


const Router = () => { 
  const global = useStore('global');
  const keys = Object.keys(locales);
  const values = Object.values(locales);

  return useObserver(() => {
    const { lang } = global;

    const messages = keys.reduce((obj, key, index) => {
      obj[key] = values[index][lang.toLowerCase()];
      return obj;
    }, {});

    return (
      <IntlProvider locale={lang} messages={messages} defaultRichTextElements={richTextConfig}>
        <div className="content">
          <BrowserRouter>
            <Header />
            <WaveContainer />
            <Switch>
              <Route exact path="/" component={Wrapper(Home)} />
              <Route exact path="/pricing" component={Wrapper(Pricing)} />
              <Route exact path="/realisations" component={Wrapper(Realisations)} />
              <Route exact path="/contact" component={Wrapper(Contact)} />
              <Route exact path="/developers" component={Wrapper(Developers)} />
              <Route exact path="/tutorials/:tutorial" component={Wrapper(Tutorials)} />
              <Route exact path="/b2b" component={Wrapper(Business)} />
              <Route exact path="/projects/:project" component={getDynamicComponent} />
              <Route path="*" component={() => <Redirect to="/" />} />
            </Switch>
            <Footer />
          </BrowserRouter>   
        </div>
      </IntlProvider>
    )
  })
};

export default Router;
