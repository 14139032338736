import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const DBMatic = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'DBMatic',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="dbmatic_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage id="possibilities_info_dbmatic" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_dbmatic" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:info@deboermarine.nl">info@deboermarine.nl</a>
          </div>

          <div>
            <i className="fas fa-phone" />
            <a href="tel:+31527308500">+31-527 308 500</a>
          </div>

          <div>
            <i className="fas fa-globe" />
            <a href="https://www.deboermarine.nl">www.deboermarine.nl</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            <img src={require('./images/vsat.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/deboermarine_bridge.jpg').default} alt="" />
          </div>
        </div>
      </section>
    </main>
  )
};


export default DBMatic;
