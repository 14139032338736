import { observable, decorate, computed } from 'mobx';
import { param, Validator } from './';

class Mail {
  constructor(data) {
    this.validate = new Validator();
    this.name = param(data, 'name');
    this.message = param(data, 'message');
    this.email = param(data, 'email');

    this.validation = {
      name: input => this.validate.input(input, 'enter_a_valid_name'),
      message: input => this.validate.input(input, 'enter_a_valid_message'),
      email: input => this.validate.email(input, 'enter_a_valid_email'),
    };
    this.validate.initializeErrors(this, this.validation);
  }


  get activeErrors() {
    const errors = Object.keys(this).filter(key => key.includes('error') && this[key].error);
    return errors.map(error => this[error]);
  }

  toJS = () => {
    return {
      name: this.name,
      message: this.message,
      email: this.email,
    }
  }
}

decorate(Mail, {
  name: observable,
  message: observable,
  email: observable,
  activeErrors: computed
})

export default Mail

