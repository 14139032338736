import { observable, decorate, observe } from 'mobx';
import { doFetchBlob } from '../utils';

const URLS = {
  DOWNLOAD_DOCUMENT: document => `/api/document/download/${document}`
}

class Global {
  constructor() {
    this.lang = this.loadStoredLanguage();
    this.selectedOption = undefined;

    this.languages = [
      {short: 'nl', long: 'Nederlands'},
      {short: 'fr', long: 'Français'},
      {short: 'de', long: 'Deutsch'},
      {short: 'en', long: 'English'},
      {short: 'es', long: 'Español'},
      {short: 'it', long: 'Italiano'},
    ];

    observe(this, 'lang',  ({oldValue, newValue}) => {
      if (oldValue !== newValue) {
        this.updateLanguage(newValue);
      }
    });
  }

  downloadDocument = file => {
    doFetchBlob(URLS.DOWNLOAD_DOCUMENT(file), 'GET')
      .then(blob => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);

      });
  }

  loadStoredLanguage = () => {
    let languageToLoad = 'en';
    const possibleLanguages = ['es', 'en', 'fr', 'de', 'it', 'nl'];
    const localStorageResult = localStorage.getItem('_site_lang');

    if (localStorageResult && possibleLanguages.includes(localStorageResult.toLocaleLowerCase())) {
      languageToLoad = localStorageResult.toLowerCase();
    }

    return languageToLoad;
  };

  updateLanguage = (lang) => {
    this.lang = lang;
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('_site_lang', lang.toLowerCase());
    }
  };
}

decorate(Global, {
  lang: observable,
  selectedOption: observable
});

export default new Global();
