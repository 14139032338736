import React, {useEffect} from 'react';
import { useObserver } from 'mobx-react-lite';
import {useLocation} from 'react-router-dom';

const Wrapper = Component => {
  return ({...props}) => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }, [pathname])

    return useObserver(() => { 
      return (
        <Component {...props} />
      )
    })
  }
};

export default Wrapper;
