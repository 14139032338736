class Client {
  constructor(name, img) {
    this.name = name.replace('_', '');
    this.img = require(`../assets/images/partners/${img}`).default;
    this.project = name.toLowerCase();

    this.link = name.replace(' ', '_');
  }
}

class ClientStore {
  constructor() {
    this.clients = [
      new Client('BirthWatch', 'birthwatch.svg'),
      new Client('Came', 'came.png'),
      new Client('DBMatic', 'dbmatic.svg'),
      new Client('Faraconnect', 'faraconnect.svg'),
      new Client('Howest', 'howest.jpg'),
      new Client('Omnic', 'omnic.png'),
      new Client('Wify', 'wify.png'),
      new Client('Veenhuizen', 'veenhuizen.png'),
      new Client('Mecodo', 'mecodo.png'),
      new Client('DTfroid', 'dtfroid.png'),
      new Client('Rudy Strubbe', 'rudystrubbe.jpg'),
      new Client('NormaCoolNord', 'nomacool.png'),
      new Client('Trusteat', 'trusteat.png'),
      new Client('Rovin', 'rovin.png'),
      new Client('Fooddesk', 'fooddesk.jpg'),
      new Client('JifeLine', 'jifeline.png'),
    ];
  }
}

export default new ClientStore();
