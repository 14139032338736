
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const Birthwatch = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'JifeLine',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="jifeline_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage 
            id="possibilities_info_jifeline" 
          />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_jifeline" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:info@jifeline.com">info@jifeline.com</a>
          </div>

          <div>
            <i className="fas fa-phone" />
            <a href="tel:+31854863727">+31-(0)85 486 3727</a>
          </div>

          <div>
            <i className="fas fa-globe" />
            <a href="https://www.jifeline.com">www.jifeline.com</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            <img src={require('./images/JFL4.jpg').default} />
          </div>

          <div>
            <img src={require('./images/JFL5.jpg').default} />
          </div>

          <div>
            <img src={require('./images/JFL6.jpg').default} />
          </div>

        </div>
      </section>
    </main>
  )
};


export default Birthwatch;
