import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const DTfroid = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'Dtfroid',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="dtfroid_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage id="possibilities_info_dtfroid" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_dtfroid" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:contact@dtfroid.com">contact@dtfroid.com</a>
          </div>

          <div>
            <i className="fas fa-phone" />
            <a href="tel:+33320088325">+33- (0)320 08 83 25</a>
          </div>

          <div>
            <i className="fas fa-globe" />
            <a href="https://www.dt-froid.com">www.dt-froid.com</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            <img src={require('./images/dtf1.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/dtf2.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/dtf4.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/DTFroid TempMon.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/DPT LoRa tempsens.jpg').default} alt="" />
          </div>
        </div>
      </section>
    </main>
  )
};


export default DTfroid;
