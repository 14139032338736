import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const Mecodo = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'Mecodo',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="mecodo_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage id="possibilities_info_mecodo" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_mecodo" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:info@mecodo.com">info@mecodo.com</a>
          </div>

          <div>
            <i className="fas fa-phone" />
            <a href="tel:+3250628487">+32(0)50 62 84 87</a>
          </div>

          <div>
            <i className="fas fa-globe" />
            <a href="https://www.mecodo.com">www.mecodo.com</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            <img src={require('./images/Meco 1.jpeg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/meco 2.jpeg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/SC Analoge IO module.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/Mecodo RTD sensor module.jpg').default} alt="" />
          </div>
        </div>
      </section>
    </main>
  )
};


export default Mecodo;
