import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../../assets/styles/projectDetails.module.scss';

const Wify = () => {
  return (
    <main className={styles.projectDetails}>
      <h1>
        <FormattedMessage id="realisation_name" 
          values={{
            project: 'Wify',
          }}
        />
      </h1>

      <section>
        <h2>
          <FormattedMessage id="project" />
        </h2>

        <p>
          <FormattedMessage id="wify_project_info" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="possibilities" />
        </h2>

        <p>
          <FormattedMessage id="possibilities_info_wify" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="applications_project" />
        </h2>

        <p>
          <FormattedMessage id="applications_wify" />
        </p>
      </section>

      <section>
        <h2>
          <FormattedMessage id="contact_details" />
        </h2>

        <div className={styles.icons}>
          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:info@wify.eu">info@wify.eu</a>
          </div>

          <div>
            <i className="fas fa-phone" />
            <a href="tel:+32487076459">+32-(0)487 07 64 59</a>
          </div>

          <div>
            <i className="fas fa-globe" />
            <a href="https://www.wify.eu">www.wify.eu</a>
          </div>
        </div>
      </section>

      <section>
        <h2>
          <FormattedMessage id="gallery" />
        </h2>

        <div className={styles.gallery}>
          <div>
            <img src={require('./images/WF1.png').default} alt="" />
          </div>

          <div>
            <img src={require('./images/WF2.png').default} alt="" />
          </div>

          <div>
            <img src={require('./images/BTS Badge.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/BTS banner.jpg').default} alt="" />
          </div>

          <div>
            <img src={require('./images/GSC WiFy160.jpg').default} alt="" />
          </div>
        </div>
      </section>
    </main>
  )
};


export default Wify;
