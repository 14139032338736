export const handler = (props, key, param) => {
  const { context, name, afterChange } = props;
  let entity;
  if (context.props !== undefined) {
    if (context.props.entity !== undefined) {
      entity = context.props.entity;
    } else {
      entity = context.props
    }
  } else {
    entity = context;
  }


  if (name.includes('.')) {
    const keys = name.split('.');
    return keys.reduce((obj, child, index) => {
      if (index + 1 === keys.length) {

        switch(key) {
          case 'update':
            const oldValue = obj[child];
            obj[child] = param;

            if (afterChange !== undefined) {
              afterChange({ oldValue, newValue: param});
            }
            break;

          case 'validate':
            try {
              const errorResult = obj.validation[child](param);
              obj[`error${child.replace(/\./g, '')}`] = {...errorResult, name};
              return {...errorResult, name};
            } catch {
              console.error(`valdation for ${child} is not set`)
            }
            break;

          case 'getErrorMessage':
            return obj[`error${child.replace(/\./g, '')}`];

          default:
            return obj[child];
        }
      }


      return obj[child];
    }, entity);

  } else {
    switch(key) {
      case 'update':
        const oldValue = entity[name];
        entity[name] = param;

        if (afterChange !== undefined) {
          afterChange({ oldValue, newValue: param });
        }

        break;

      case 'getErrorMessage':
        return entity[`error${name.replace(/\./g, '')}`];

      case 'validate':
        try {
          const errorResult = entity.validation[name](param);
          entity[`error${name.replace(/\./g, '')}`] = errorResult;
          return errorResult;
        } catch {
          console.error(`valdation for ${name} is not set`)
        }

        break;

      default:
        return entity[name];
    }
  }
}

export const getValue = props => {
  const value = handler(props, 'getValue');
  return value;
};

export const getErrorMessage = props => {
  return handler(props, 'getErrorMessage');
};

export const validate = (props, value) => {
  return handler(props, 'validate', value);
};

export const  setValue = (props, value) => {
  return handler(props, 'update', value);
};

