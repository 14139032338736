import React, {useState, useEffect } from 'react';
import { useObserver } from "mobx-react-lite"
import { useParams, useLocation } from 'react-router-dom';
import { useStores, useRedirect } from '../../hooks';

import styles from '../../assets/styles/dev.module.scss';
import notFound from '../../assets/images/not_found.svg';


const Topic = ({ topic, redirect }) => {
  const { devStore } = useStores(['devStore']);
  const { activeTutorial } = devStore;
  const location = useLocation();

  const setActiveTopic = () => {
    activeTutorial.setActiveTopic(topic);
    redirect(`${location.pathname}#${topic?.id}?t=${Date.now()}`);
  };

  return useObserver(() => {
    return (
      <li>
        <span className={topic.active ? styles.active : undefined } onClick={setActiveTopic}>- {topic.topic}</span>
      </li>
    )
  })

};

const MobileContent = ({ activeTutorial, redirectToTopic }) => {
  return (
    <div className={`${styles.mobileContainer} ${styles.mobile}`}>
      <div className={styles.dropdown}>
        <div>
          <span>
            Topics
          </span>

          <i className="fas fa-chevron-down" />
        </div>

        <ul className={styles.dropdownContent}>
          {
            activeTutorial?.topics.map(topic => <Topic redirect={redirectToTopic} topic={topic} key={topic.name}/>)
          }
        </ul> 
      </div>

      <main style={{paddingTop: '40px'}}>
        {
          activeTutorial !== undefined && activeTutorial.jsx() 
        }
      </main> 
    </div> 
  )
};


const DesktopContent = ({ activeTutorial, redirectToTopic }) => {
  return (
    <div className={`${styles.container} ${styles.desktop}`}>

      {
        activeTutorial !== undefined && activeTutorial.jsx !== null && (
          <>
            <aside>
              <ul>
                {
                  activeTutorial.topics.map(topic => {
                    return (
                      <Topic topic={topic} redirect={redirectToTopic} key={`desktop-${topic.id}`}/>
                    )
                  })
                }
              </ul>
            </aside>

            <main style={{paddingTop: '40px'}}>
              {
                activeTutorial !== undefined && activeTutorial.jsx() 
              }
            </main>
          </>
        )
      }
    </div>
  )
};

const getDistances = activeTutorial => {
  return activeTutorial.topics.reduce((values, topic) => {
    const elem = document.getElementById(`${topic.id.replace('#', '')}`);

    if (elem) {
      const distance = elem.getBoundingClientRect().top
      return [...values, {id: topic.id, distance }];
    }

    return values;
  }, []);
};


const getClosestTopic = topics => {
  return topics.reduce((closestTopic, topic) => {
    if (topic.distance > 0 && (!closestTopic || closestTopic.distance > topic.distance)) {
      closestTopic = topic;
    }

    return closestTopic;
  }, undefined);
};


const Tutorials = () => {
  const { devStore } = useStores(['devStore']);
  const [allowScroll, setAllowScroll] = useState(true);
  const [time, setTime] = useState(Date.now());
  const { redirect } = useRedirect();
  const { hash } = useLocation();
  const params = useParams();

  useEffect(() => {
    if (params.tutorial) {
      devStore.setActiveTutorial(params.tutorial);
    }
  }, [])

  useEffect(() => {
    const onScroll = () => {
      if (allowScroll && time + 600 < Date.now()) {
        const { activeTutorial } = devStore;

        if (activeTutorial) {
          const topics = getDistances(activeTutorial);
          const closetTopic = getClosestTopic(topics);
          activeTutorial.setActiveTopic(closetTopic);
        }
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    }
  }, [allowScroll, time]);

  useEffect(() => {
    setTimeout(() => {
      const { activeTutorial } = devStore;

      if (hash) {
        const id = `${hash.replace('#', '').split('?')[0]}`;
        const elem = document.getElementById(id);

        if (elem) {
          const y = elem.getBoundingClientRect().top + window.pageYOffset + -120;
          window.scrollTo({top: y, behavior: 'smooth'});
          activeTutorial.setActiveTopic({ id });
          setAllowScroll(true);
        }
      }
    }, 10)

  }, [hash])

  const redirectToTopic = url => {
    setTime(Date.now());
    redirect(url);
  };


  return useObserver(() => {
    const { activeTutorial } = devStore;

    if (activeTutorial === undefined || activeTutorial?.jsx === null) {
      return (
        <div className={styles.imgContainer}>
          <h1>Couldn't find the page you were looking for...</h1>
          <img src={notFound} alt="not_found" />
        </div>
      )
    }


    return (
      <>
        <DesktopContent activeTutorial={activeTutorial} redirectToTopic={redirectToTopic}/>
        <MobileContent activeTutorial={activeTutorial} redirectToTopic={redirectToTopic}/>
      </>
    ) 
  })
}

export default Tutorials;

