import 'babel-polyfill';
import '@fortawesome/fontawesome-free/css/all.css'
import React, {useEffect} from 'react';
import { useObserver } from 'mobx-react-lite';
import { ToastContainer } from 'react-toastify';
import { supressErrorNames } from '../utils';
import Router from './Router';
import '../assets/styles/screen.scss';

export default () => { 
  useEffect(() => {
    supressErrorNames();
  }, []);

  return useObserver(() => { 
    return (
      <>
        <ToastContainer />
        <Router />
      </>
    )
  })
};
