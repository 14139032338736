import { decorate, observable } from 'mobx';
import { Tutorial } from '../components/models';
import { translate } from '../components/I18n';
import { 
  GettingStarted,
  ReactTutorial,
  NodeTutorial 
} from '../components/Topics';

import bluecherryImg from '../assets/images/topics/bluecherry.jpg';
import nodeImg from '../assets/images/topics/backend.jpg';
import reactImg from '../assets/images/topics/react.jpg';



class Topic {
  constructor(topic, id, active = false) {
    this.topic = topic;
    this.id = id;
    this.active = active;
  }
}

decorate(Topic, {
  topic: observable,
  id: observable,
  active: observable
});

class devStore {
  constructor() {
    this.activeTutorial = undefined;

    this.tutorials = [
      new Tutorial({
        title: translate('t_configure_your_device_for_bluecherry'),
        id: 'get-started',
        description: translate('c_configure_your_device_for_bluecherry'),
        image: bluecherryImg,
        jsx: GettingStarted,
        topics: [
          new Topic('Getting started', 'getting-started'),
          new Topic('Install the BlueCherry Client', 'install-bc-client'),
          new Topic('Provision your device', 'provision-device'),
          new Topic('Start the client', 'start-client'),
        ]
      }),
      new Tutorial({
        title: translate('t_node_backend'),
        description: translate('c_node_backend'),
        image: nodeImg,
        id: 'node-backend',
        jsx: NodeTutorial,
        topics: [
          new Topic('Getting started', 'getting-started'),
          new Topic('Create a nodeJS project', 'create-project'),
          new Topic('Install the BlueCherry SDK', 'install-bc-sdk'),
          new Topic('Example', 'example'),
        ]
      }),
      new Tutorial({
        title: translate('t_react_application'),
        jsx: ReactTutorial,
        description: translate('c_react_application'),
        image: reactImg,
        id: 'react-application',
        topics: [
          new Topic('Getting started', 'getting-started'),
          new Topic('Create a react project', 'create-react-project'),
          new Topic('Install the bluecherry SDK', 'install-bc-sdk'),
          new Topic('Use nginx as a proxy', 'nginx-setup'),
          new Topic('Example', 'example-react'),
        ]
      })
    ];
  }

  setActiveTutorial = id => {
    const match = this.tutorials.find(tutorial => tutorial.id === id)

    if (match) {
      this.activeTutorial = match;
    }
  }
}

decorate(devStore, {
  tutorials: observable,
  activeTutorial: observable
});

export default new devStore();

