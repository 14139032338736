import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { FormattedMessage } from 'react-intl';
import styles from '../../assets/styles/footer.module.scss';
import fit_logo from '../../assets/images/fit_logo.png';
import {useStores} from '../../hooks';

const Footer = () => {
  const { global } = useStores(['global']);

  const downloadTermsOfUse = () => {
    global.downloadDocument('terms_of_use_policy.pdf');
  };

  const downloadCookiePolicy = () => {
    global.downloadDocument('cookie_policy.pdf');
  };

  const downloadPrivacyPolicy = () => {
    global.downloadDocument('privacy_policy.pdf');
  };

  return useObserver(() => {
    return (
      <footer className={styles.footer}>
        <div>

        </div>
        <div className={styles.contactDetails}>
          <div>
            <i className="fas fa-phone" />
            <a href="tel:050622888">
              +32 (0)50 62 28 88
            </a>
          </div>

          <div>
            <i className="fas fa-envelope" />
            <a href="mailto:info@bluecherry.io">info@bluecherry.io</a>
          </div>

          <div>
            <i className="fas fa-map-marked-alt" />
            <span>
              Westkapellestraat 396/44
              <br />
              8300 Knokke-Heist
            </span>
          </div>

          <div>
            <i className="fas fa-info"></i>
            VAT BE0429 557 768
          </div>
        </div> 

        <div className={styles.logo}>
          <img src={fit_logo} alt="fit logo" />
        </div>


        <div className={styles.rules}>
          <span className={styles.copyright}>© { new Date().getFullYear() } Interkim N.V.</span>

          <div className={styles.policies}>
            <span onClick={downloadTermsOfUse}>
              <FormattedMessage id="terms_of_use" />
            </span>
            <span onClick={downloadCookiePolicy}>
              <FormattedMessage id="cookies" />
            </span>
            <span onClick={downloadPrivacyPolicy}>
              <FormattedMessage id="privacy" />
            </span>
          </div>
        </div>
      </footer>
    )
  })
}

export default Footer;
