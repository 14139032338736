import { doFetchBlob } from '../utils';

const URLS = {
  DOWNLOAD_FILE: file => `/api/file/download/${file}`
}

class FileStore {
  downloadFile = async (file, filename) => {
    return doFetchBlob(URLS.DOWNLOAD_FILE(file), 'GET')
      .then(blob => {
        // 2. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename || file);
        // 3. Append to html page
        document.body.appendChild(link);
        // 4. Force download
        link.click();
        // 5. Clean up and remove the link
        link.parentNode.removeChild(link);

      });
  }
}

export default new FileStore();

