import {decorate, observable} from "mobx";

class Tutorial {
  constructor({name, jsx = null, active = false, id = '', topics = [], image, title, description}) {
    this.id = id;
    this.name = name;
    this.jsx = jsx;
    this.active = active;
    this.image = image;
    this.topics = topics;
    this.title = title;
    this.description = description;

    if (this.topics.length > 0) {
      this.topics[0].active = true;
    }
  }

  setActiveTopic = topic => {
    if (topic === undefined) {
      return;
    }

    const { id } = topic;
    this.topics.forEach(topic => {
      topic.active = topic.id === id;
    });
  }
}

decorate(Tutorial, {
  name: observable,
  id: observable,
  jsx: observable,
  active: observable,
  image: observable,
  topics: observable,
  description: observable,
  title: observable
});

export default Tutorial;
