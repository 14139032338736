import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from '../../assets/styles/dev.module.scss';
import { CodeBlock } from '../elements';

const NodeTutorial = () => {
  return (
    <>
      <h2 id="getting-started">
        <FormattedMessage id="get_started" />
      </h2>

      <p>
        <FormattedMessage id="c_node_get_started" />
      </p>

      <div className={styles.requirements}>
        <p>
          Requirements:
        </p>

        <ul>
          <li>- NodeJS installed</li>
          <li>- An active BlueCherry device subscription (this can be bought via the webshop)</li>
          <li>- Access to your provisioned device</li>
          <li>- Active BC Client</li>
        </ul>
      </div>

      <div>
        <div className={styles.gettingStartedContainer} id="create-project">
          <p className={styles.title}>
            <FormattedMessage id="t_create_nodejs_project" />
          </p>

          <p>
            <FormattedMessage id="c_create_nodejs_project" />
          </p>

          <CodeBlock code="mkdir bluecherry-example" language="bash" />

          <p>
            <FormattedMessage id="c_go_to_the_created_directory" />
          </p>

          <CodeBlock code="cd bluecherry-example" language="bash" />

          <p>
            <FormattedMessage id="c_initialize_this_project" />
          </p>

          <CodeBlock code="yarn init -y" language="bash" />

        </div>

        <div className={styles.gettingStartedContainer} id="install-bc-sdk">
          <p className={styles.title}>
            <FormattedMessage id="t_install_bluecherry_sdk" />
          </p>

          <p>
            <FormattedMessage id="c_install_bluecherry_sdk" />
          </p>


          <CodeBlock code="yarn add bluecherry-communication" language="bash" />
        </div>

        <div  className={styles.gettingStartedContainer} id="example">
          <p className={styles.title}>
            <FormattedMessage id="t_example" />
          </p>

          <CodeBlock 
            code={`
import * as bluecherry from 'bluecherry-communication';

bluecherry.sendMail({
  destination: <Destination>,
  subject: <Subject>,
  plain: <PlainText>,
  html: <HtmlFormat>
});
             `}

            language="javascript"
          />

        </div>
      </div>
    </>
  )
};

export default NodeTutorial;
